"use strict";
import myHttp from "./myAxios";
import store from "../store";

import { post, put } from "./myAxios";

/**
 *
 * @param {options} options 参数集合
 * @param {url}    url *: 地址
 */
const API = {
  //----------图片上传----------
  // 批量上传
  batchFileUploadAli(params) {
    return myHttp({
      url: "common/batchFileUploadAli",
      method: "post",
      params,
    });
  },
  //文件上传
  // http://112.74.161.140/yicheng-app/api/v1/common/fileUploadAli
  fileUploadAli(data, type) {
    return myHttp({
      url: "common/fileUploadAli",
      method: "post",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  // =========== 三方 ============
  // 三方登录路径获取
  getConAddressSer(params) {
    return myHttp({
      url: "room/getConAddressSer",
      method: "get",
      params,
    });
  },
  // 三方登录路径获取
  getWxState(params) {
    return myHttp({
      url: "user/getUserBindOrNot",
      method: "get",
      params,
    });
  },
  // 三方账号绑定
  bindOpenId(params) {
    return myHttp({
      url: "user/bindOpenId",
      method: "post",
      params,
    });
  },
  // 三方登录
  mobileThirdLogin(params) {
    return myHttp({
      url: "user/mobileThirdLogin",
      method: "post",
      params,
    });
  },
  // 获取QQ登录路径
  getAuthPageUrl(params) {
    return myHttp({
      url: "QQLogin/getAuthPageUrl",
      method: "get",
      params,
    });
  },
  // 获取QQ扫码结果
  getQQUserId(params) {
    return myHttp({
      url: "QQLogin/getUserId",
      method: "get",
      params,
    });
  },
  // QQ新建用户
  setCreateUser(params) {
    return myHttp({
      url: "QQLogin/createUser",
      method: "get",
      params,
    });
  },
  // 检验短信验证码
  checkSend(params) {
    return myHttp({
      url: "sms/checkSend",
      method: "get",
      params,
    });
  },
  // 三方解绑
  cancelBindOpenId(params) {
    return myHttp({
      url: "user/cancelBindOpenId",
      method: "post",
      params,
    });
  },
  // ---------- 首充 ------------
  // 获取首充礼物信息
  userRechargeFirst(params) {
    return myHttp({
      url: "user/getFirstList",
      method: "get",
      params,
    });
  },
  // 获取用户充值倒计时
  getUserFirstRech(params) {
    return myHttp({
      url: "user/getUserFirstRech",
      method: "get",
      params,
    });
  },
  // ============== 提现 ===============
  // 获取提现比例
  withdrawData(params) {
    return myHttp({
      url: "withdraw/withdrawData",
      method: "get",
      params,
    });
  },
  //账户提现申请
  withdrawApply(params) {
    return myHttp({
      url: "withdraw/withdrawApply",
      method: "post",
      params,
    });
  },
  //提现记录
  queryWithdraws(params) {
    return myHttp({
      url: "withdraw/queryWithdraws",
      method: "get",
      params,
    });
  },
  // ---------- 微信支付 ------------
  // 获取微信支付二维码
  weChatPaymentPc(params) {
    return myHttp({
      url: "recharge/wxPayPc",
      method: "post",
      params,
    });
  },
  // 获取微信支付结果
  weChatPayResult(params) {
    return myHttp({
      url: "recharge/getWxPayResult",
      method: "get",
      params,
    });
  },
  // ---------- 图形验证码 ------------
  // 获取图形验证码
  randomImage(params) {
    return myHttp({
      url: "user/getcodeImage",
      method: "get",
      params,
    });
  },
  // 验证图形验证码
  checkImage(params) {
    return myHttp({
      url: "user/checkImage/checkCode",
      method: "get",
      params,
    });
  },
  // ---------- 使用阿里云发送验证码 ------------
  // 获取验证码
  getAliSendCode(params) {
    return myHttp({
      url: "sms/aliSend",
      method: "post",
      params,
    });
  },
  // ---------- 腾讯登录 ------------
  // 融云用户注册token
  rcRegisterToken(params) {
    return myHttp({
      url: "rc/register",
      method: "get",
      params,
    });
  },
  // ---------- 意见反馈 --------
  // 提交反馈
  setAddFeedback(params) {
    return myHttp({
      url: "feedback/addFeedback",
      method: "post",
      params,
    });
  },
  // 反馈记录
  getQueryFeedbackList(params) {
    return myHttp({
      url: "feedback/queryFeedbackList",
      method: "get",
      params,
    });
  },
  // 反馈类型
  queryFeedbackType(params) {
    return myHttp({
      url: "feedback/queryFeedbackType",
      method: "get",
      params,
    });
  },
  // ---------- 轮播图 ----------
  bannerList(params) {
    return myHttp({
      url: "banner/list",
      method: "get",
      params,
    });
  },
  // ----------- 全频消息 -------
  // 发送全频消息
  sendWholeMessage(params) {
    return myHttp({
      url: "room/fullScreenMessage",
      method: "post",
      params,
    });
  },
  // 获取全频道发送广播价格
  getSendAllPrice(params) {
    return myHttp({
      url: "room/getSendAllPrice",
      method: "get",
      params,
    });
  },
  // 获取全频道发送广播财富等级限制
  getSendAllLevel(params) {
    return myHttp({
      url: "room/getSendAllLevel",
      method: "get",
      params,
    });
  },
  // 获取各协议规则说明等
  getAgreement(params) {
    return myHttp({
      url: "config/getAgreement",
      method: "get",
      params,
    });
  },
  // ---------- 搜索 -----------
  // /api/v1/config/queryConfig
  queryConfig(params) {
    return myHttp({
      url: "config/queryConfig",
      method: "get",
      params,
    });
  },
  // 搜索大神列表
  searchPlayerList(params) {
    return myHttp({
      url: "search/searchPlayerList",
      method: "get",
      params,
    });
  },
  //搜索聊天室列表
  searchRoomList(params) {
    return myHttp({
      url: "search/searchRoomList",
      method: "get",
      params,
    });
  },
  //房间二级分类列表
  reclassifyList(params) {
    return myHttp({
      url: "room/reclassifyList",
      method: "get",
      params,
    });
  },
  //---------- 用户登录 ---------
  // 注册账号
  userRegister(params) {
    return myHttp({
      url: "user/register",
      method: "post",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  // 账号密码登录
  login(params) {
    return myHttp({
      url: "user/login",
      method: "POST",
      params,
    });
  },
  // 手机号登录
  loginForCaptcha(params) {
    return myHttp({
      url: "user/loginForCaptcha",
      method: "POST",
      params,
    });
  },
  // ------------  用户信息   ------------
  // 用户信息
  queryUser(params) {
    return myHttp({
      url: "user/queryUser",
      method: "get",
    });
  },
  // 用户信息
  userInfo(params) {
    return myHttp({
      url: "user/userInfo",
      method: "get",
    });
  },
  //他人主页主页
  userMainInfo(params) {
    return myHttp({
      url: "user/personMainPageInfo",
      method: "get",
      params,
    });
  },
  // 投诉原因
  complainList(params) {
    return myHttp({
      url: "user/complain/list",
      method: "get",
      params,
    });
  },
  // 新增投诉
  complainAdd(params) {
    return myHttp({
      url: "user/userComplain/add",
      method: "post",
      params,
    });
  },
  // 支付宝支付
  alipayVip(params) {
    return myHttp({
      url: "vipBuyRecord/aliPay",
      method: "post",
      params,
    });
  },
  // 创建群组
  createGroup(params) {
    return myHttp({
      url: "group/createGroup",
      method: "post",
      params,
    });
  },
  // 群组详情
  groupDetails(params) {
    return myHttp({
      url: "group/groupDetails",
      method: "get",
      params,
    });
  },
  // 创建群Id
  createGroupId(params) {
    return myHttp({
      url: "group/createGroupId",
      method: "post",
      params,
    });
  },

  
  // group/createGroup

  // 获取个人标签列表
  getUserPersonalTag(params) {
    return myHttp({
      url: "user/getUserPersonalTag",
      method: "get",
    });
  },
  // 获取声音标签列表
  getUserVoiceTag(params) {
    return myHttp({
      url: "user/getUserVoiceTag",
      method: "get",
    });
  },
  // 获取感兴趣标签列表
  getInterestTags(params) {
    return myHttp({
      url: "user/getInterestTags",
      method: "get",
    });
  },
  // 通过userNo获取user信息
  getUserByUserNo(params) {
    return myHttp({
      url: "user/getUserByUserNo",
      method: "get",
      params,
    });
  },
  // 保存个人信息
  saveUserInfo(params) {
    return myHttp({
      url: "user/saveUserInfo",
      method: "post",
      params,
    });
  },
  // 获取用户分享二维码
  getShare(params) {
    return myHttp({
      url: "user/getShare",
      method: "get",
      params,
    });
  },
  // 查询用户注销申请状态
  getCancelApplyStatus(params) {
    return myHttp({
      url: "user/getCancelApplyStatus",
      method: "get",
      params,
    });
  },
  //校验用户是否存在开黑房权限和是否可以赠送龙币
  isRoomwhitelist(params) {
    return myHttp({
      url: "userRole/isRoomwhitelist",
      method: "get",
      params,
    });
  },
  //获取赠送龙币转化元宝比例
  giveGoldsProp(params) {
    return myHttp({
      url: "user/userGoldAccount/giveGoldsProp",
      method: "get",
      params,
    });
  },
  //赠送龙币
  giveGolds(params) {
    return myHttp({
      url: "user/userGoldAccount/giveGolds",
      method: "post",
      params,
    });
  },
  // ======== 用户信息修改 ========
  // 变更手机号
  changeMobile(data) {
    return myHttp({
      url: "user/changeMobile",
      method: "post",
      data,
    });
  },
  // 修改密码
  changePwd(params) {
    return myHttp({
      url: "user/changePwd",
      method: "post",
      params,
    });
  },
  // 忘记密码
  forgetPwd(params) {
    return myHttp({
      url: "user/forgetPwd",
      method: "post",
      params,
    });
  },
  // 个性化推荐推送开关
  pushSwitch(data) {
    return myHttp({
      url: "user/pushSwitch",
      method: "post",
      data,
    });
  },
  // palyer/mainPagePlayerList
  // 注销申请
  cancelApply(params) {
    return myHttp({
      url: "user/cancelApply",
      method: "post",
      params,
    });
  },
  // =========== 用户认证 ==========
  // 用户认证-获取用户实名认证信息
  getUserRealNameData(params) {
    return myHttp({
      url: "userAttest/userAttest/getUserRealNameData",
      method: "get",
      params,
    });
  },
  // 用户认证-实名认证
  userRealNameAuthentication(params) {
    return myHttp({
      url: "userAttest/userAttest/userRealNameAuthentication",
      method: "post",
      params,
    });
  },
  // 校验用户是否实名认证
  isAttest(params) {
    return myHttp({
      url: "userRole/isAttest",
      method: "get",
      params,
    });
  },
  // 用户技能认证添加
  userSkills(params) {
    return myHttp({
      url: "userAttest/userAttest/userSkills",
      method: "post",
      params,
    });
  },
  // 编辑个人资质
  editUserSkill(params) {
    return myHttp({
      url: "userAttest/userAttest/editUserSkill",
      method: "post",
      params,
    });
  },
  // 获取技能段位列表
  labelLevelInfoList(params) {
    return myHttp({
      url: "palyer/labelLevelInfoList",
      method: "get",
      params,
    });
  },
  // ============= 用户操作 =============

  // 停止关注用户
  stopFollowUser(params) {
    return myHttp({
      url: "entity/follow/stopFollowUser",
      method: "get",
      params,
    });
  },
  // 用户照片墙-添加
  userImgAdd(params) {
    return myHttp({
      url: "userImg/userImg/add",
      method: "post",
      params,
    });
  },
  // 用户照片墙-通过id删除
  userImgDelete(params) {
    return myHttp({
      url: "userImg/userImg/delete",
      method: "post",
      params,
    });
  },
  // 用户拉黑
  addBlack(params) {
    return myHttp({
      url: "entity/follow/addBlack",
      method: "post",
      params,
    });
  },
  // 取消用户拉黑
  removeBlack(params) {
    return myHttp({
      url: "entity/follow/removeBlack",
      method: "post",
      params,
    });
  },
  // 黑名单列表
  userBlackList(params) {
    return myHttp({
      url: "entity/follow/blackList",
      method: "get",
      params,
    });
  },
  // 关注用户
  followUser(params) {
    return myHttp({
      url: "entity/follow/followUser",
      method: "get",
      params,
    });
  },
  // 取消关注用户
  stopFollowUser(params) {
    return myHttp({
      url: "entity/follow/stopFollowUser",
      method: "get",
      params,
    });
  },
  // 关注或粉丝列表
  followOrFansList(params) {
    return myHttp({
      url: "entity/follow/followOrFansList",
      method: "get",
      params,
    });
  },
  // 上传我的声音
  propertiesOfMyVoice(params) {
    return myHttp({
      url: "user/propertiesOfMyVoice",
      method: "get",
      params,
    });
  },
  // --------------- 举报 ------------------
  // 举报原因
  queryReportReason(params) {
    return myHttp({
      url: "report/queryReportReason",
      method: "get",
      params,
    });
  },
  // 举报用户
  report(params) {
    return myHttp({
      url: "report/report",
      method: "post",
      params,
    });
  },
  // ---------------大神用户管理 -------------
  // 分类标签大神列表
  getHomeManito(params) {
    return myHttp({
      url: "palyer/mainPagePlayerList",
      method: "get",
      params,
    });
  },
  getHomeLabel(params) {
    return myHttp({
      url: "palyer/labelPlayerList",
      method: "get",
      params,
    });
  },

  
  // 大神开黑-获取大神技能评价信息
  playerEvaluationList(params) {
    return myHttp({
      url: "palyer/playerEvaluationList",
      method: "get",
      params,
    });
  },
  // 大神个人主页-礼物数和勋章数统计
  playerGiftAndMedalNum(params) {
    return myHttp({
      url: "palyer/playerGiftAndMedalNum",
      method: "get",
      params,
    });
  },
  // 大神个人主页-礼物墙接口
  playerGiftWallList(params) {
    return myHttp({
      url: "palyer/playerGiftWallList",
      method: "get",
      params,
    });
  },
  
  // 大神个人主页-勋章墙接口
  playerMedalWallList(params) {
    return myHttp({
      url: "palyer/playerMedalWallList",
      method: "get",
      params,
    });
  },
  // 大神开黑-获取大神技能信息
  playerSkillInfo(params) {
    return myHttp({
      url: "palyer/playerSkillInfo",
      method: "get",
      params,
    });
  },

  // 折扣大神列表
  disCountPlayerList(params) {
    return myHttp({
      url: "discount/disCountPlayerList",
      method: "get",
      params,
    });
  },
  // 折扣大神列表
  guessLike(params) {
    return myHttp({
      url: "palyer/guessLike",
      method: "get",
      params,
    });
  },
  // 折扣配置
  discountConfig(params) {
    return myHttp({
      url: "discount/config",
      method: "get",
      params,
    });
  },
  // 用户技能认证-获取等级单价区服选项列表
  getOptions(params) {
    return myHttp({
      url: "userAttest/userAttest/getOptions",
      method: "get",
      params,
    });
  },
  // 用户技能-单个资质开启或关闭
  editOneOnOff(params) {
    return myHttp({
      url: "userAttest/userAttest/editOneOnOff",
      method: "post",
      params,
    });
  },
  // 用户技能-我的资质批量开启或关闭
  editOnOffState(params) {
    return myHttp({
      url: "userAttest/userAttest/editOnOffState",
      method: "post",
      params,
    });
  },
  // ------------ 大神资质 ----------------
  // 查看个人资质
  selectUserSkill(params) {
    return myHttp({
      url: "userAttest/userAttest/selectUserSkill",
      method: "post",
      params,
    });
  },
  // 获取大神技能流水
  getSkillStreamInfo(params) {
    return myHttp({
      url: "userAttest/userAttest/getSkillStreamInfo",
      method: "get",
      params,
    });
  },
  // 获取活动设置和说明和消息设置
  getDiscountInfo(params) {
    return myHttp({
      url: "userAttest/userAttest/getDiscountInfo",
      method: "get",
      params,
    });
  },
  // 活动设置
  discountSet(params) {
    return myHttp({
      url: "userAttest/userAttest/discountSet",
      method: "post",
      params,
    });
  },
  // ------------ 标签分类 ----------------
  // 热门分类
  hotLabelList(params) {
    return myHttp({
      url: "interestLabel/hotLabelList",
      method: "get",
      params,
    });
  },
  // 标签分类查询
  allLabelList(params) {
    return myHttp({
      url: "interestLabel/list",
      method: "get",
      params,
    });
  },
  // 获取所有二级分类
  // getAllSecondLabel(params) {
  //   return myHttp({
  //     url: "interestLabel/getAllSecondLabel",
  //     method: "get",
  //     params
  //   })
  // },
  // 热门-游戏-娱乐，三个分类下所有分类
  getAllSecondLabel(params) {
    return myHttp({
      url: "interestLabel/labelListByFirstType",
      method: "get",
      params,
    });
  },
  // 标签分类查询
  interestLabelList(params) {
    return myHttp({
      url: "interestLabel/list",
      method: "get",
      params,
    });
  },
  //----------------- 盲盒 ------------
  // 盲盒抽奖
  doLottery(params) {
    return myHttp({
      url: "lottery/doLottery",
      method: "post",
      params,
    });
  },
  // 获取抽奖价格
  lotteryPrice(params) {
    return myHttp({
      url: "lottery/lotteryPrice",
      method: "get",
      params,
    });
  },
  //获取抽奖数量配置
  lotteryNumConfig(params) {
    return myHttp({
      url: "lottery/lotteryNumConfig",
      method: "get",
      params,
    });
  },
  // 盲盒奖池列表
  prizePoolDetailList(params) {
    return myHttp({
      url: "lottery/prizePoolDetailList",
      method: "get",
      params,
    });
  },
  // 抽奖页奖品列表
  prizePoolList(params) {
    return myHttp({
      url: "lottery/prizePoolList",
      method: "get",
      params,
    });
  },
  // 盲盒抽奖规则
  lotteryRule(params) {
    return myHttp({
      url: "lottery/lotteryRule",
      method: "get",
    });
  },
  //中奖记录
  prizeRecord(params) {
    return myHttp({
      url: "lottery/prizeRecord",
      method: "get",
      params,
    });
  },
  //---------------- 搜索管理 -------------
  //热门聊天室列表
  hotRoomList(params) {
    return myHttp({
      url: "search/hotRoomList",
      method: "get",
      params,
    });
  },
  //聊天室列表
  allRoomList(params) {
    return myHttp({
      url: "room/roomList",
      method: "get",
      params,
    });
  },
  

  // entity/follow/addressBookList
  //随机房间
  getRandomRoomId(params) {
    return myHttp({
      url: "room/getRandomRoomId",
      method: "get",
      params,
    });
  },
  //---------------- 创建聊天室 -------------
  //获取创建游戏聊天室选项列表
  getCreateGameRoomOptions(params) {
    return myHttp({
      url: "roomOpenBlack/getCreateGameRoomOptions",
      method: "get",
      params,
    });
  },
  //创建开黑房
  createOpenBlackRoom(params) {
    return myHttp({
      url: "roomOpenBlack/createOpenBlackRoom",
      method: "post",
      params,
    });
  },
  //关闭房间
  closeRoom(data) {
    return myHttp({
      url: "roomOpenBlack/closeRoom",
      method: "post",
      data,
    });
  },
  //---------------- 聊天室 -------------
  //最新麦位
  roomPostrionMesage(params) {
    return myHttp({
      url: "room/roomPostrionMesage",
      method: "post",
      params,
    });
  },
  //进入聊天室-聊天室详情
  enterRoom(params) {
    return myHttp({
      url: "room/enteRoomCommon",
      method: "get",
      params,
    });
  },
  //退出聊天室
  signOutRoom(params) {
    return myHttp({
      url: "room/signOutRoom",
      method: "get",
      params,
    });
  },
  //娱乐模式切换
  modeSwit(params) {
    return myHttp({
      url: "room/modeSwit",
      method: "get",
      params,
    });
  },
  
  // /roomOpenBlack/upMicroApplyList
  //厅排行榜
  roomRanking(params) {
    return myHttp({
      url: "room/roomRanking",
      method: "get",
      params,
    });
  },
  //获取麦位用户
  getMicphoneUser(params) {
    return myHttp({
      url: "room/getMicphoneUser",
      method: "get",
      params,
    });
  },
  //获取全部麦位用户
  getSimplePositionInfoByRoomId(params) {
    return myHttp({
      url: "room/getSimplePositionInfoByRoomId",
      method: "get",
      params,
    });
  },
  //他人名片
  getOtherInfo(params) {
    return myHttp({
      url: "room/getOtherInfo",
      method: "get",
      params,
    });
  },
  //聊天室-聊天室麦位信息
  getPositionByRoomId(params) {
    return myHttp({
      url: "room/getPositionByRoomId",
      method: "get",
      params,
    });
  },
  //聊天室-pc排行榜
  getRoomPcRankList(params) {
    return myHttp({
      url: "room/getRoomPcRankList",
      method: "get",
      params,
    });
  },
  //聊天室-pc页面左侧在线，vip，点单，试音，排麦数量
  getRoomPcLeftNumbers(params) {
    return myHttp({
      url: "room/getRoomPcLeftNumbers",
      method: "get",
      params,
    });
  },
  //聊天室-公告
  getReport(params) {
    return myHttp({
      url: "room/getReport",
      method: "get",
      params,
    });
  },
  //举报房间
  roomReport(params) {
    return myHttp({
      url: "room/report",
      method: "get",
      params,
    });
  },
  //编辑聊天室公告
  chatRoomAnnouncement(params) {
    return myHttp({
      url: "room/chatRoomAnnouncement",
      method: "post",
      params,
    });
  },
  //---------------- 相亲相爱模式 -------------
  //发送消息
  sendMessage(params) {
    return myHttp({
      url: "room/sendMessage",
      method: "get",
      params,
    });
  },
  //聊天室-选择对象
  choiceUser(params) {
    return myHttp({
      url: "loveRoom/choiceUser",
      method: "post",
      params,
    });
  },
  //聊天室-切换阶段
  changeStage(params) {
    return myHttp({
      url: "loveRoom/changeStage",
      method: "POST",
      params,
    });
  },
  //聊天室-相亲恋爱玩法说明
  getPlayWithRule(params) {
    return myHttp({
      url: "loveRoom/getPlayWithRule",
      method: "get",
      params,
    });
  },
  //聊天室-真爱匹配成功列表
  getTrueLoveList(params) {
    return myHttp({
      url: "loveRoom/trueLoveList",
      method: "post",
      params,
    });
  },
  // --------------- 聊天室房间PK模式 ---------------
  //聊天室-点唱PK房列表
  getPkRoomList(params) {
    return myHttp({
      url: "room/getPkRoomList",
      method: "post",
      params,
    });
  },
  //聊天室-点唱房pk邀请
  getInvitationSingRoom(params) {
    return myHttp({
      url: "room/getInvitationSingRoom",
      method: "get",
      params,
    });
  },
  //聊天室-同意or拒绝
  agreeOrRefuse(params) {
    return myHttp({
      url: "room/agreeOrRefuse",
      method: "get",
      params,
    });
  },
  //开始PK-获取PK动画
  startPK(params) {
    return myHttp({
      url: "voiceRoom/startPK",
      method: "get",
      params,
    });
  },
  //结束pk
  endPK(params) {
    return myHttp({
      url: "voiceRoom/endPK",
      method: "get",
      params,
    });
  },
  // 获取房内PK礼物前三与进度
  getTeamValue(params) {
    return myHttp({
      url: "room/getTeamValue",
      method: "get",
      params,
    });
  },
  // 获取房房PK礼物前三与进度
  getRoomPkTeamValue(params) {
    return myHttp({
      url: "room/getRoomPkTeamValue",
      method: "get",
      params,
    });
  },
  // 获取邀请房间的数据
  getInvitationRoom(params) {
    return myHttp({
      url: "room/getInvitationRoom",
      method: "get",
      params,
    });
  },
  //---------------- 聊天室消息 -------------
  //聊天室-活动结束
  finshLoveRoom(params) {
    return myHttp({
      url: "loveRoom/finshLoveRoom",
      method: "post",
      params,
    });
  },

  //发送全频广播
  setAllMessage(data) {
    return myHttp({
      url: "room/setAllMessage",
      method: "get",
      data,
    });
  },
  //给指定房间发送消息
  sendMessage2Room(params) {
    return myHttp({
      url: "rc/sendMessage2Room",
      method: "get",
      params,
    });
  },
  //---------------- 聊天室设置 -------------
  //获取用户房间设置
  getRoomUserSet(params) {
    return myHttp({
      url: "room/getRoomUserSet",
      method: "get",
      params,
    });
  },
  //设置用户房间设置
  setRoomUserSet(params) {
    return myHttp({
      url: "room/setRoomUserSet",
      method: "post",
      params,
    });
  },
  // ============== 点唱厅 ==============
  //获取点唱厅演唱者
  getVoicePlayer(params) {
    return myHttp({
      url: "voiceRoom/getVoicePlayer",
      method: "get",
      params,
    });
  },
  //获取点唱试音-点歌-包场-场地费费用
  getVoiceFree(params) {
    return myHttp({
      url: "voiceRoom/getVoiceFree",
      method: "get",
      params,
    });
  },
  //点唱-确定下单
  confirmOrder(params) {
    return myHttp({
      url: "voiceRoom/confirmOrder",
      method: "post",
      params,
    });
  },
  //我的元宝
  myDiamond(params) {
    return myHttp({
      url: "voiceRoom/myDiamond",
      method: "get",
      params,
    });
  },
  // ============== 开黑房 ==============
  //在线用户列表
  onlineUserList(params) {
    return myHttp({
      url: "roomOpenBlack/onlineUserList",
      method: "get",
      params,
    });
  },
  //获取聊天室音乐列表
  getRoomMusics(params) {
    return myHttp({
      url: "roomOpenBlack/getRoomMusics",
      method: "get",
      params,
    });
  },
  //添加我的音乐
  addMyMusic(data) {
    return myHttp({
      url: "roomOpenBlack/addMyMusic",
      method: "post",
      data,
    });
  },
  //获取聊天室主题列表
  getRoomThemeList(params) {
    return myHttp({
      url: "roomOpenBlack/getRoomThemeList",
      method: "get",
      params,
    });
  },
  //上麦申请列表
  upMicroApplyList(params) {
    return myHttp({
      url: "roomOpenBlack/upMicroApplyList",
      method: "get",
      params,
    });
  },
  //房间上锁|解锁
  lockRoom(params) {
    return myHttp({
      url: "roomOpenBlack/lockRoom",
      method: "post",
      params,
    });
  },
  //房间管理员列表
  roomManagersInfo(params) {
    return myHttp({
      url: "roomOpenBlack/roomManagersInfo",
      method: "get",
      params,
    });
  },
  //房间访客列表
  roomVisitorInfo(params) {
    return myHttp({
      url: "roomOpenBlack/roomVisitorInfo",
      method: "get",
      params,
    });
  },
  //设置|取消管理
  setManager(params) {
    return myHttp({
      url: "roomOpenBlack/setManager",
      method: "post",
      params,
    });
  },
  //房间禁言|解除禁言操作
  muteUser(params) {
    return myHttp({
      url: "roomOpenBlack/muteUser",
      method: "post",
      params,
    });
  },
  // 获取禁言天数集合
  muteDays(params) {
    return myHttp({
      url: "roomOpenBlack/muteDays",
      method: "post",
      params,
    });
  },
  //房间拉黑|取消拉黑操作
  blackUser(params) {
    return myHttp({
      url: "roomOpenBlack/blackUser",
      method: "post",
      params,
    });
  },
  //房间黑名单
  roomBlackList(params) {
    return myHttp({
      url: "roomOpenBlack/roomBlackList",
      method: "get",
      params,
    });
  },
  //房间禁言名单
  roomMuteList(params) {
    return myHttp({
      url: "roomOpenBlack/roomMuteList",
      method: "get",
      params,
    });
  },
  //房间踢人
  kickUser(params) {
    return myHttp({
      url: "roomOpenBlack/kickUser",
      method: "post",
      params,
    });
  },
  //房间管理页面房间详细信息
  roomDetailInfo(params) {
    return myHttp({
      url: "roomOpenBlack/roomDetailInfo",
      method: "get",
      params,
    });
  },
  //房间管理页面房间基础信息
  roomInfo(params) {
    return myHttp({
      url: "roomOpenBlack/roomInfo",
      method: "get",
      params,
    });
  },
  //保存房间背景图
  saveRoomBack(params) {
    return myHttp({
      url: "roomOpenBlack/saveRoomBack",
      method: "post",
      params,
    });
  },
  //查询所有背景数据
  selRoomImage(params) {
    return myHttp({
      url: "room/selRoomImage",
      method: "get",
      params,
    });
  },
  // ============== 房主操作 ==============
  //清空聊天室内所有用户礼物值
  clearRoomUserGiftValue(params) {
    return myHttp({
      url: "room/clearGift",
      method: "get",
      params,
    });
  },
  // ============== 主持人 ==============
  //获取主持人任务
  getHostTask(params) {
    return myHttp({
      url: "room/getHostTask",
      method: "get",
      params,
    });
  },
  //设置主持人礼物任务
  saveHostGiftTask(params) {
    return myHttp({
      url: "room/saveHostGiftTask",
      method: "get",
      params,
    });
  },
  //删除主持人礼物任务
  removeUserTask(params) {
    return myHttp({
      url: "room/removeUserTask",
      method: "get",
      params,
    });
  },
  // ============== 礼物 ==============
  //获取礼物清单
  getGiftList(params) {
    return myHttp({
      url: "gift/getGiftList",
      method: "get",
      params,
    });
  },
  //我的礼物背包
  myGiftList(params) {
    return myHttp({
      url: "gift/myGiftList",
      method: "get",
      params,
    });
  },
  //聊天室赠送礼物
  sendGift(params) {
    return myHttp({
      url: "roomGift/sendGift",
      method: "get",
      params,
    });
  },
  // 获取麦位用户麦位框
  getMicrophoneDetail(params) {
    return myHttp({
      url: "room/getMicrophoneDetail",
      method: "get",
      params,
    });
  },
  // ============= 聊天室用户操作 =============
  //上麦
  upMicrophone(params) {
    return myHttp({
      url: "room/upMicrophone",
      method: "post",
      params,
    });
  },
  //同意上麦邀请
  acceptInvite(params) {
    return myHttp({
      url: "room/acceptInvite",
      method: "post",
      params,
    });
  },
  //取消上麦申请
  cancelUpMicrophone(params) {
    return myHttp({
      url: "room/cancelUpMicrophone",
      method: "post",
      params,
    });
  },
  //锁定或解锁麦位
  lockPosition(params) {
    return myHttp({
      url: "room/lockPosition",
      method: "post",
      params,
    });
  },
  //拒绝上麦
  rejectUpMicrophone(params) {
    return myHttp({
      url: "room/rejectUpMicrophone",
      method: "post",
      params,
    });
  },
  //拒绝上麦邀请
  rejectInvite(params) {
    return myHttp({
      url: "room/rejectInvite",
      method: "post",
      params,
    });
  },
  //抱用户上麦
  hugUserToPosition(params) {
    return myHttp({
      url: "room/hugUserToPosition",
      method: "post",
      params,
    });
  },
  //抱用户下麦&下麦
  hugUserFromPosition(params) {
    return myHttp({
      url: "room/hugUserFromPosition",
      method: "post",
      params,
    });
  },
  //全员闭麦或全员开麦
  closeAllMicrophone(params) {
    return myHttp({
      url: "room/closeAllMicrophone",
      method: "post",
      params,
    });
  },
  //闭麦或开麦
  closeMicrophoneOn(params) {
    return myHttp({
      url: "room/closeMicrophone",
      method: "post",
      params,
    });
  },
  //闭麦位或开麦位
  closePosition(params) {
    return myHttp({
      url: "room/closePosition",
      method: "post",
      params,
    });
  },
  //收藏|取消收藏房间
  collectRoom(params) {
    return myHttp({
      url: "room/collectRoom",
      method: "post",
      params,
    });
  },
  roomCollection(params) {
    return myHttp({
      url: "room/roomCollection",
      method: "get",
      params,
    });
  },
  // /tjyy-app/api/v1/room/roomCollection
  //-------------- 聊天室音乐 ------------
  //音乐列表
  getMyMusics(params) {
    return myHttp({
      url: "roomOpenBlack/getMyMusics",
      method: "get",
      params,
    });
  },
  // ------------- 派单厅 ------------
  //派单房-派单
  orderDispatch(params) {
    return myHttp({
      url: "order/orderDispatch/dispatch",
      method: "get",
      params,
    });
  },
  //派单房-截单 停止派单
  stopOrderStatus(params) {
    return myHttp({
      url: "order/orderDispatch/stopOrderStatus",
      method: "post",
      params,
    });
  },
  //-------------- 排行榜 ------------
  // 榜单说明
  rankRules(params) {
    return myHttp({
      url: "rules/rankRules",
      method: "get",
      params,
    });
  },
  // ---------- 财富榜 ----------
  // 财富榜-日榜
  wealthListDay(params) {
    return myHttp({
      url: "rank/wealthListDay",
      method: "get",
      params,
    });
  },
  // 财富榜-月榜
  wealthListMonth(params) {
    return myHttp({
      url: "rank/wealthListMonth",
      method: "get",
      params,
    });
  },
  // 财富榜-周榜
  wealthListWeek(params) {
    return myHttp({
      url: "rank/wealthListWeek",
      method: "get",
      params,
    });
  },
  // 财富榜-总榜
  wealthList(params) {
    return myHttp({
      url: "rank/wealthList",
      method: "get",
      params,
    });
  },
  // ---------- 魅力榜 ----------
  // 魅力榜-日榜
  charismaListDay(params) {
    return myHttp({
      url: "rank/charismaListDay",
      method: "get",
      params,
    });
  },
  // 魅力榜-周榜
  charismaListWeek(params) {
    return myHttp({
      url: "rank/charismaListWeek",
      method: "get",
      params,
    });
  },
  // 魅力榜-月榜
  charismaListMonth(params) {
    return myHttp({
      url: "rank/charismaListMonth",
      method: "get",
      params,
    });
  },
  // 魅力榜-总榜
  charismaList(params) {
    return myHttp({
      url: "rank/charismaList",
      method: "get",
      params,
    });
  },
  // ---------- 礼物榜 ----------
  // 礼物榜-日榜
  giftListDay(params) {
    return myHttp({
      url: "rank/giftListDay",
      method: "get",
      params,
    });
  },
  // 礼物榜-周榜
  giftListWeek(params) {
    return myHttp({
      url: "rank/giftListWeek",
      method: "get",
      params,
    });
  },
  // 礼物榜-月榜
  giftListMonth(params) {
    return myHttp({
      url: "rank/giftListMonth",
      method: "get",
      params,
    });
  },
  // 礼物榜-总榜
  giftList(params) {
    return myHttp({
      url: "rank/giftList",
      method: "get",
      params,
    });
  },
  //真爱榜
  trueLoveList(params) {
    return myHttp({
      url: "rank/trueLoveList",
      method: "get",
      params,
    });
  },
  // ============= 消息管理 =============
  //消息列表
  queryAllList(params) {
    return myHttp({
      url: "message/queryAllList",
      method: "get",
      params,
    });
  },
  //各类型最新消息
  queryLastMessage(params) {
    return myHttp({
      url: "message/queryLastMessage",
      method: "get",
      params,
    });
  },
  // 同意转让群聊

  operateGroup(params) {
    return myHttp({
      url: "group/changeOperateGroup",
      method: "post",
      params,
    });
  },
  getGroupList(params) {
    return myHttp({
      url: "group/groupList",
      method: "get",
      params,
    });
  },
  managerList(params) {
    return myHttp({
      url: "group/memberManagerList",
      method: "get",
      params,
    });
  },
  outControl(params) {
    return myHttp({
      url: "group/outControl",
      method: "post",
      params,
    });
  },
  intoAddressBook(params) {
    return myHttp({
      url: "group/intoAddressBook",
      method: "post",
      params,
    });
  },
  getMemberList(params) {
    return myHttp({
      url: "group/memberList",
      method: "get",
      params,
    });
  },
  chatFileRecord(params) {
    return myHttp({
      url: "chat/chatFileRecord/list",
      method: "get",
      params,
    });
  },
  getShareGroup(params) {
    return myHttp({
      url: "group/getShare",
      method: "post",
      params,
    });
  },

  

  
  

 
  

  
  // group/changeOperateGroup


  //消息未读数接口
  unreadCount(params) {
    return myHttp({
      url: "message/unreadCount",
      method: "get",
      params,
    });
  },
  //根据类型标记消息已读
  readByType(params) {
    return myHttp({
      url: "message/readByType",
      method: "post",
      params,
    });
  },
  // ============= 道具商城 =============
  //我的道具-装扮道具
  dressProps(params) {
    return myHttp({
      url: "userProps/userProps/dressProps",
      method: "post",
      params,
    });
  },
  //我的道具-分页列表查询
  userPropsList(params) {
    return myHttp({
      url: "userProps/userProps/list",
      method: "get",
      params,
    });
  },
  //道具商城-热门推荐
  hotProps(params) {
    return myHttp({
      url: "prop/propsInfo/hotProps",
      method: "get",
      params,
    });
  },
  //道具商城-道具价格信息
  propPriceInfo(params) {
    return myHttp({
      url: "prop/propsInfo/propPriceInfo",
      method: "get",
      params,
    });
  },
  //道具商城-最新上架
  newProps(params) {
    return myHttp({
      url: "prop/propsInfo/newProps",
      method: "get",
      params,
    });
  },
  //道具商城-分页列表查询
  propsInfoList(params) {
    return myHttp({
      url: "prop/propsInfo/list",
      method: "get",
      params,
    });
  },
  //道具商城-道具订单下单
  buyProps(params) {
    return myHttp({
      url: "prop/propsInfo/buyProps",
      method: "post",
      params,
    });
  },
  //我的道具-取消装扮道具
  cancelDressProps(params) {
    return myHttp({
      url: "userProps/userProps/cancelDressProps",
      method: "post",
      params,
    });
  },
  // ============= 帮助中心 =============
  //问题列表
  queryHelpList(params) {
    return myHttp({
      url: "help/queryHelpList",
      method: "get",
      params,
    });
  },
  // ============== 账户管理 ==============
  //账户-钱包详情
  queryAccount(params) {
    return myHttp({
      url: "account/queryAccount",
      method: "get",
      params,
    });
  },
  //账户-获取兑换龙币数据
  exchangeGoldCoins(params) {
    return myHttp({
      url: "giftGive/giftGive/exchangeGoldCoins",
      method: "get",
      params,
    });
  },
  //账户-确认兑换龙币
  immediatelyChange(params) {
    return myHttp({
      url: "giftGive/giftGive/immediatelyChange",
      method: "get",
      params,
    });
  },
  //账户-获取兑换龙币转化元宝比例
  goldsToDiamondsProp(params) {
    return myHttp({
      url: "user/userGoldAccount/goldsToDiamondsProp",
      method: "get",
      params,
    });
  },
  //账户-确认龙币兑换元宝
  diamondsChange(params) {
    return myHttp({
      url: "user/userGoldAccount/diamondsChange",
      method: "get",
      params,
    });
  },
  // ============== 元宝账户表 ==============
  //我的元宝余额
  userDiamondAccountBalance(params) {
    return myHttp({
      url: "user/userDiamondAccount/balance",
      method: "get",
      params,
    });
  },
  //元宝账户表-分页列表查询
  userDiamondAccountList(params) {
    return myHttp({
      url: "user/userDiamondAccount/list",
      method: "get",
      params,
    });
  },
  //元宝账户表-通过id查询
  queryById(params) {
    return myHttp({
      url: "user/userDiamondAccount/queryById",
      method: "get",
      params,
    });
  },
  //我的钱包-元宝
  queryByMyDiamond(params) {
    return myHttp({
      url: "user/userDiamondAccount/queryByMyDiamond",
      method: "get",
      params,
    });
  },
  //元宝账户记录表-分页列表查询
  userDiamondAccountBillList(params) {
    return myHttp({
      url: "user/userDiamondAccountBill/list",
      method: "get",
      params,
    });
  },
  // ============== 龙币账户表 ==============
  //我的钱包-龙币
  queryByMyGold(params) {
    return myHttp({
      url: "user/userGoldAccount/queryByMyGold",
      method: "get",
      params,
    });
  },
  //立即兑换元宝
  diamondsChange(params) {
    return myHttp({
      url: "user/userGoldAccount/diamondsChange",
      method: "get",
      params,
    });
  },
  //龙币账户记录表-分页列表查询
  goldPageList(params) {
    return myHttp({
      url: "user/userGoldAccountBill/goldPageList",
      method: "get",
      params,
    });
  },
  // ============== 我的礼物 ==============
  // 我的钱包-收到、兑换礼物数
  giftInfo(params) {
    return myHttp({
      url: "giftGive/giftGive/giftInfo",
      method: "get",
      params,
    });
  },
  // 收到、送出礼物列表
  giftGiveList(params) {
    return myHttp({
      url: "giftGive/giftGive/giftSendAndGetList",
      method: "get",
      params,
    });
  },
  // ============== 订单管理 ==============
  //立即下单页面信息
  immediateOrder(params) {
    return myHttp({
      url: "order/immediateOrder",
      method: "get",
      params,
    });
  },
  //陪玩订单确认下单
  belowOrder(params) {
    return myHttp({
      url: "order/order",
      method: "post",
      params,
    });
  },
  //道具订单
  propsOrder(params) {
    return myHttp({
      url: "order/propsOrder",
      method: "get",
      params,
    });
  },
  //陪玩订单分页列表查询
  playWithList(params) {
    return myHttp({
      url: "order/list",
      method: "get",
      params,
    });
  },
  //我的接单分页列表查询
  takeMyOrders(params) {
    return myHttp({
      url: "order/takeMyOrders",
      method: "get",
      params,
    });
  },
  //道具订单
  queryAccount(params) {
    return myHttp({
      url: "order/propsOrder",
      method: "get",
      params,
    });
  },
  // ================ 陪玩订单 ================
  //通过id删除订单
  orderDelete(params) {
    return myHttp({
      url: "order/delete",
      method: "post",
      params,
    });
  },
  //陪玩订单确认完成
  endOrder(params) {
    return myHttp({
      url: "order/endOrder",
      method: "post",
      params,
    });
  },
  //评价陪玩订单
  evaluationOrder(params) {
    return myHttp({
      url: "order/evaluationOrder",
      method: "post",
      params,
    });
  },
  //陪玩订单开始订单
  startOrder(params) {
    return myHttp({
      url: "order/startOrder",
      method: "post",
      params,
    });
  },
  //陪玩订单接单
  takeOrder(params) {
    return myHttp({
      url: "order/takeOrder",
      method: "post",
      params,
    });
  },
  //陪玩订单拒绝接单
  rejectOrder(params) {
    return myHttp({
      url: "order/rejectOrder",
      method: "post",
      params,
    });
  },
  //陪玩订单取消订单
  cancelOrder(params) {
    return myHttp({
      url: "order/cancelOrder",
      method: "post",
      params,
    });
  },
  //投诉陪玩订单
  complainOrder(params) {
    return myHttp({
      url: "order/complainOrder",
      method: "post",
      params,
    });
  },
  // ================ 签到 ================
  //签到表-分页列表查询
  currentMonth(params) {
    return myHttp({
      url: "signIn/currentMonth",
      method: "get",
      params,
    });
  },
  //签到
  signIn(params) {
    return myHttp({
      url: "signIn/signIn",
      method: "get",
      params,
    });
  },
  // ================ 财富特权 ================
  // 财富特权等级表-分页列表查询
  wealthLevelList(data) {
    return myHttp({
      url: "level/wealthLevel/list",
      method: "get",
      data,
    });
  },
  // 财富特权等级表-通过id查询
  wealthLevelQueryById(data) {
    return myHttp({
      url: "level/wealthLevel/queryById",
      method: "get",
      data,
    });
  },
  //获取财富特权列表
  getWealthPrivilegeList(data) {
    return myHttp({
      url: "level/wealthLevel/getWealthPrivilegeList",
      method: "get",
      data,
    });
  },
  //财富说明
  getWealData(params) {
    return myHttp({
      url: "level/wealthLevel/getWealData",
      method: "get",
      params,
    });
  },
  // 财富等级进度
  wealthLevelProgress(params) {
    return myHttp({
      url: "level/wealthLevel/wealthLevelProgress",
      method: "get",
      params,
    });
  },
  //财富特权列表 -- 财富说明列表
  wealthPrivilege(params) {
    return myHttp({
      url: "level/wealthLevel/wealthPrivilege",
      method: "get",
      params,
    });
  },
  //财富特权列表 -- 财富说明列表
  wealthPrivilege(params) {
    return myHttp({
      url: "level/wealthLevel/wealthPrivilege",
      method: "get",
      params,
    });
  },
  //获取财富等级信息
  getWealthLevelInfo(data) {
    return myHttp({
      url: "level/wealthLevel/getWealthLevelInfo",
      method: "get",
      data,
    });
  },
  //获取财富等级说明
  getWealthLevelExplain(data) {
    return myHttp({
      url: "level/wealthLevel/getWealthLevelExplain",
      method: "get",
      data,
    });
  },
  // ================ 用户房间信息 ================
  // 语聊厅
  languageToChatRoom(params) {
    return myHttp({
      url: "room/languageToChatRoom",
      method: "get",
      params,
    });
  },
  // 开黑厅
  playTogetherRoom(params) {
    return myHttp({
      url: "room/playTogetherRoom",
      method: "get",
      params,
    });
  },
  //  ============= 公会查询 ===============
  // 通过userid查询我入驻的公会信息
  queryGuildByUserId(params) {
    return myHttp({
      url: "guild/guildUser/queryGuildByUserId",
      method: "get",
      params,
    });
  },
  // 公会用户表-分页列表查询
  guildUserList(params) {
    return myHttp({
      url: "guild/guildUser/list",
      method: "get",
      params,
    });
  },
  // ================ 公会入驻 ================
  // 公会（入驻）表-添加
  addGuild(params) {
    return myHttp({
      url: "guild/guild/add",
      method: "post",
      params,
    });
  },
  // 公会（入驻）表-分页列表查询
  guildList(params) {
    return myHttp({
      url: "guild/guild/list",
      method: "get",
      params,
    });
  },
  // 通过邀请码查询
  queryByInviteCode(params) {
    return myHttp({
      url: "guild/guild/queryByInviteCode",
      method: "get",
      params,
    });
  },
  // 查询推荐公会信息
  queryRecommendInfo(params) {
    return myHttp({
      url: "guild/guild/queryRecommendInfo",
      method: "get",
      params,
    });
  },
  // 公会用户表-通过id查询
  queryById(params) {
    return myHttp({
      url: "guild/guildUser/queryById",
      method: "get",
      params,
    });
  },
  // 公会管理
  queryByUserId(params) {
    return myHttp({
      url: "guild/guildUser/queryByUserId",
      method: "get",
      params,
    });
  },
  // 撤销退会申请
  cancalQuit(params) {
    return myHttp({
      url: "guild/guildUserQuit/cancalQuit",
      method: "post",
      params,
    });
  },
  // 撤销加入公会申请
  withdrawById(params) {
    return myHttp({
      url: "guild/guildUser/withdrawById",
      method: "get",
      params,
    });
  },
  // 用户申请入会记录
  getApplyInfo(params) {
    return myHttp({
      url: "guild/guildUserApply/getApplyInfo",
      method: "get",
      params,
    });
  },
  // 用户申请入会
  guildUserApplyAdd(params) {
    return myHttp({
      url: "guild/guildUserApply/add",
      method: "post",
      params,
    });
  },
  // 大神续约
  greatGodRenewal(params) {
    return myHttp({
      url: "guild/guildUser/greatGodRenewal",
      method: "post",
      params,
    });
  },
  // ============== 申请退出公会 =============
  // 用户申请退会-退会选项和退回说明
  getQuitOptionAndExplain(params) {
    return myHttp({
      url: "guild/guildUserQuit/getQuitOptionAndExplain",
      method: "get",
      params,
    });
  },
  // 用户申请退会-友好退会
  setNicelyQuit(params) {
    return myHttp({
      url: "guild/guildUserQuit/nicelyQuit",
      method: "post",
      params,
    });
  },
  // 用户申请退会-无条件退会
  setNoConditionQuit(params) {
    return myHttp({
      url: "guild/guildUserQuit/noConditionQuit",
      method: "post",
      params,
    });
  },
  // 用户申请退会-无条件退会
  setNoConditionQuit(params) {
    return myHttp({
      url: "guild/guildUserQuit/noConditionQuit",
      method: "post",
      params,
    });
  },
  // 用户申请退会-获取强制退会费
  getForceQuitFee(params) {
    return myHttp({
      url: "guild/guildUserQuit/forceQuitFee",
      method: "post",
      params,
    });
  },
  // 用户申请退会-强制退会
  setForceQuit(params) {
    return myHttp({
      url: "guild/guildUserQuit/forceQuit",
      method: "post",
      params,
    });
  },
  // 用户申请退会表-通过id查询
  guildQueryById(params) {
    return myHttp({
      url: "guild/guildUserQuit/queryById",
      method: "get",
      params,
    });
  },
  // ================ 充值 ================
  //获取充值菜单
  queryRechargeMenu: (params) => {
    return myHttp({
      url: "recharge/queryRechargeMenu",
      method: "GET",
      params,
    });
  },
  //支付宝充值
  aliPay: (params) => {
    return myHttp({
      url: "recharge/aliPay",
      method: "post",
      params,
    });
  },
  //支付宝充值PC
  aliPayPc: (params) => {
    return myHttp({
      url: "recharge/aliPayPc",
      method: "post",
      params,
    });
  },
  // /room/acceptUpMicrophone
  acceptUpMicrophone:(params) => {
    return myHttp({
      url: "room/acceptUpMicrophone",
      method: "post",
      params,
    });
  },
  //查询支付宝充值结果
  aliPayResult: (params) => {
    return myHttp({
      url: "recharge/getAliPayResult",
      method: "get",
      params,
    });
  },
  //微信充值
  wxPay: (params) => {
    return myHttp({
      url: "recharge/wxPay",
      method: "post",
      params,
    });
  },
  // ================ 系统配置 ================
  //获取各字典数据等
  getDictItem: (params) => {
    return myHttp({
      url: "config/getDictItem",
      method: "GET",
      params,
    });
  },
  //根据分组查询系统配置
  getConfig: (params) => {
    return myHttp({
      url: "config/queryByConfigGroup",
      method: "GET",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //配件商城
  peiJian: (params) => {
    return myHttp({
      url: "/goods/secondGoods/shopUrl",
      method: "GET",
    });
  },
  // ================ oss ================
  //获取oss上传所需数据
  getOssConfig: (params) => {
    return myHttp({
      url: "config/getAliStsToken",
      method: "GET",
    });
  },
  //根据分组查询系统配置
  queryByConfigGroup: (params) => {
    return myHttp({
      url: "config/queryByConfigGroup",
      method: "GET",
      params,
    });
  },
  //上传base64图片到阿里云OSS
  senMorePic: (params) => {
    return myHttp({
      url: "/common/fileUploadBase64Ali",
      method: "POST",
      params,
    });
  },
  // ================ 账号密码 ================
  //账号密码登录
  login: (params) => {
    return myHttp({
      url: "/user/login",
      method: "POST",
      params, // 想发送post请求，并且传递的参数拼接到url后边，就用params作为键名，传递参数即可。   无需拼接到url后边时，用data作为键名即可
    });
  },
  //根据token获取个人信息
  getInfo: (params) => {
    return myHttp({
      url: "/user/getUserByToken",
      method: "GET",
    });
  },

  // ================ 弹窗 ================
  //意向书-添加-商务合作-机床回收
  recovery: (params) => {
    return myHttp({
      url: "/goods/intention/add",
      method: "POST",
      params,
    });
  },
  // ================ 广场 ================
  // 广场

  forumList: (params) => {
    return myHttp({
      url: "forum/forum/list",
      method: "get",
     params,
    });
  },
  topicUnderWayList: (params) => {
    return myHttp({
      url: "forum/topic/underWayList",
      method: "get",
       params,
    });
  },
  forumCommentList: (params) => {
    return myHttp({
      url: "forum/forumComment/list",
      method: "get",
      params,
    });
  },
  forumClickAdd: (params) => {
    return myHttp({
      url: "forum/forumClick/add",
      method: "POST",
       params,
    });
  },
  forumAdd: (params) => {
    return myHttp({
      url: "/forum/forum/add",
      method: "POST",
      params,
    });
  },

  //
  // ===============好友======
  // /v1/entity/follow/myFriends
  myFriends: (params) => {
    return myHttp({
      url: "entity/follow/myFriends",
      method: "get",
      params,
    });
  },
  // 通讯录
  bookEntity(params){
    return myHttp({
      url: "entity/follow/addressBookList",
      method: "get",
      params,
    });
  },

  myFriendsData(params) {
    return myHttp({
      url: "entity/follow/myFriendsData",
      method: "get",
      params,
    });
  },
  // =================分组======
  // /entity/follow/friendGroupList

  friendGroupList: (params) => {
    return myHttp({
      url: "entity/follow/friendGroupList",
      method: "get",
      params,
    });
  },
  
  // ======设置财富等级
  // /user/setWealthPrivilege
  setWealthPrivilege:(params) => {
    return myHttp({
      url: "/user/setWealthPrivilege",
      method: "post",
      params,
    });
  },
  // /forum/forum/homepageList
  homepageList:(params) => {
    return myHttp({
      url: "forum/forum/homepageList",
      method: "get",
      params,
    });
  },
  // 同意上麦
 
  agreeOrRefuse:(params) => {
    return myHttp({
      url: "room/agreeOrRefuse",
      method: "get",
      params,
    });
  },
  
  // /room/roomPkList
  roomPkList:(params) => {
    return myHttp({
      url: "room/roomPkList",
      method: "get",
      params,
    });
  },
  // /room/clearWheat
  clearWheat:(params) => {
    return myHttp({
      url: "room/clearWheat",
      method: "get",
      params,
    });
  },
  forumQueryById:(params) => {
    return myHttp({
      url: "forum/forum/queryById",
      method: "get",
      params,
    });
  },
  forumCommentAdd:(params) => {
    return myHttp({
      url: "forum/forumComment/add",
      method: "post",
      params,
    });
  },
  forumMyList:(params) => {
    return myHttp({
      url: "forum/forum/myList",
      method: "get",
      params,
    });
  },
  forumMyCollectionList:(params) => {
    return myHttp({
      url: "forum/forum/myCollectionList",
      method: "get",
      params,
    });
  },
  forumLimitAdd:(params) => {
    return myHttp({
      url: "forum/forumLimit/add",
      method: "post",
      params,
    });
  },
  voiceRoomEndPK:(params) => {
    return myHttp({
      url: "voiceRoom/endPK",
      method: "get",
      params,
    });
  },
  collectForum:(params) => {
    return myHttp({
      url: "forum/forum/collectForum",
      method: "post",
      params,
    });
  },
  secondListAll:(params) => {
    return myHttp({
      url: "forum/forumComment/secondListAll",
      method: "get",
      params,
    });
  },
  autoLockMicro:(params) => {
    return myHttp({
      url: "roomOpenBlack/autoLockMicro",
      method: "post",
      params,
    });
  },
  getIsLock:(params) => {
    return myHttp({
      url: "room/getIsLock",
      method: "get",
      params,
    });
  },
  // 收费价格
  chargesList:(params) => {
    return myHttp({
      url: "user/chargesList",
      method: "get",
      params,
    });
  },
  updateForumArea:(params) => {
    return myHttp({
      url: "forum/forumArea/updateForumArea",
      method: "post",
      params,
    });
  },
  forumAreaList:(params) => {
    return myHttp({
      url: "forum/forumArea/list",
      method: "get",
      params,
    });
  },
  forumLimitDelete:(params) => {
    return myHttp({
      url: "forum/forum/delete",
      method: "post",
      params,
    });
  },
  realLoveListRoom:(params) => {
    return myHttp({
      url: "room/realLoveList",
      method: "get",
      params,
    });
  },
  // 直播反馈 roomOpenBlack/roomFeedBack


  roomFeedBack:(params) => {
    return myHttp({
      url: "roomOpenBlack/roomFeedBack",
      method: "post",
      params,
    });
  },
  roomFeedBackTypes:(params) => {
    return myHttp({
      url: "roomOpenBlack/roomFeedBackTypes",
      method: "get",
      params,
    });
  },
  setChargeSettings:(params) => {
    return myHttp({
      url: "user/setChargeSettings",
      method: "post",
      params,
    });
  },
  getChargeSettings:(params) => {
    return myHttp({
      url: "user/getChargeSettings",
      method: "post",
      params,
    });
  },
  sendShortVideos:(params) => {
    return myHttp({
      url: "userCallRecord/sendShortVideos",
      method: "post",
      params,
    });
  },
  forumLimitList:(params) => {
    return myHttp({
      url: "forum/forumLimit/list",
      method: "get",
      params,
    });
  },
  setRoomIntercom:(params) => {
    return myHttp({
      url: "room/setRoomIntercom",
      method: "post",
      params,
    });
  },
  setUpMicroSwitch:(params) => {
    return myHttp({
      url: "room/setUpMicroSwitch",
      method: "post",
      params,
    });
  },
  forumLimitDelete1:(params) => {
    return myHttp({
      url: "forum/forumLimit/delete",
      method: "post",
      params,
    });
  },
  followAddressBookList:(params) => {
    return myHttp({
      url: "entity/follow/addressBookList",
      method: "get",
      params,
    });
  },
  queryVipTypes:(params) => {
    return myHttp({
      url: "vipType/queryVipTypes",
      method: "get",
      params, 
    });
  },
  vipBuyRecordVipTypes:(params) => {
    return myHttp({
      url: "vipBuyRecord/queryVipTypes",
      method: "get",
      params, 
    });
  },
  // 我的群组
  groupListGroup:(params) => {
    return myHttp({
      url: "group/groupList",
      method: "get",
      params, 
    });
  },
  groupListGroup:(params) => {
    return myHttp({
      url: "group/groupList",
      method: "get",
      params, 
    });
  },


  


  
  
  // entity/follow/addressBookList
  // /room/setRoomIntercom
  // /userCallRecord/sendShortVideos
  // /user/setChargeSettings
  // /forum/forumLimit/delete
  // /forum/forumLimit/list
  

  // /
  // /user/chargesList
  // /report/queryReportReason
  // /roomOpenBlack/autoLockMicro

  // forum/forumComment/secondListAll
  // /forum/forum/myCollectionList
  // forum/collectForum
  // v1/voiceRoom/endPK
  

  // /forum/forum/myCollectionList
  // /forum/forumComment/add
  // //forum/queryById
};

export default API;
