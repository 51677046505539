<template>
  <div style="color:#333" v-if="!isSHowDetail">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="我的动态" name="first">
        <div class="dynamicBox">
          <div class="dynamicItem flex" v-for="(items,index) in guangchangData" :key="index">
            <img :src="items.avatar" class="dynamicImg" alt="" />
            <div style="margin-left: 10px">
              <div class="font-16" style="font-weight: bold">{{items.nickname}}</div>
              <div
                class="font-12"
                style="color: #999999; margin: 8px 0 12px 0"
              >
                {{ items.createTimeDesc }}
              </div>
              <div class="font-14" style="color: #666666">
                <span style="color: #4177dd" v-if="items.topicName">#{{items.topicName}}</span>
                {{ items.title }}
              </div>
              <!-- 视频 -->
              <div v-if="items.videoPicture" style="position: relative" @click="playMusic(items)">
                <div style="margin-top:10px">
                  <video width="320" height="140" controls>
                    <source :src="items.video"></source>
                  </video>
                </div>
              </div>
              <!-- 图片 -->
              <template v-if="items.newImages.length>0">
              <div
                style="margin-top: 12px; width: 340px"
              >
                <img
                  :src="itemImg"
                  alt=""
                  style="
                    width: 100px;
                    height: 100px;
                    border-radius: 4px;
                    margin: 0 8px 8px 0;
                  "
                  v-for="(itemImg,indexImg) in items.newImages"
                :key="indexImg"
                />
              </div>
            </template>
              <!-- 按钮 -->
              <div class="flex" style="margin-top: 16px">
                <div class="flex flex-content" style="margin-right: 40px">
                  <img
                    src="../../static/oth7.png"
                    alt=""
                    style="width: 16px; height: 16px; margin-right: 8px"
                  />
                  <span>分享</span>
                </div>
                <div class="flex flex-content" style="margin-right: 40px" v-if="items.isLike==0" @click="zanBtn(items)">
                  <img
                    src="../../static/oth8.png"
                    alt=""
                    style="width: 16px; height: 16px; margin-right: 8px"
                  />
                  <span>{{ items.isLike==0  &&items.likeNumber==0?'首赞':items.likeNumber }} </span>
                </div>
                <div class="flex flex-content" style="margin-right: 40px" v-if="items.isLike==1" @click="zanBtn(items)">
                  <img
                    src="../../static/4.png"
                    alt=""
                    style="width: 16px; height: 16px; margin-right: 8px"
                  />
                  <span style="color:rgba(255, 167, 0, 1)">{{ items.isLike==0  &&items.likeNumber==0?'首赞':items.likeNumber }} </span>
                </div>
                <div class="flex flex-content" @click="goPingLun(items)">
                  <img
                    src="../../static/oth9.png"
                    alt=""
                    style="width: 16px; height: 16px; margin-right: 8px"
                  />
                  <span>评论</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="收藏动态" name="second">
        <div class="dynamicBox">
          <div class="dynamicItem flex" v-for="(items,index) in guangchangData" :key="index">
            <img :src="items.avatar" class="dynamicImg" alt="" />
            <div style="margin-left: 10px">
              <div class="font-16" style="font-weight: bold">{{items.nickname}}</div>
              <div
                class="font-12"
                style="color: #999999; margin: 8px 0 12px 0"
              >
                {{ items.createTimeDesc }}
              </div>
              <div class="font-14" style="color: #666666">
                <span style="color: #4177dd" v-if="items.topicName">#{{items.topicName}}</span>
                {{ items.title }}
              </div>
              <!-- 视频 -->
              <div v-if="items.videoPicture" style="position: relative" @click="playMusic(items)">
                <div style="margin-top:10px">
                  <video width="320" height="140" controls>
                    <source :src="items.video"></source>
                  </video>
                </div>
              </div>
              <!-- 图片 -->
              <template v-if="items.newImages.length>0">
              <div
                style="margin-top: 12px; width: 340px"
              >
                <img
                  :src="itemImg"
                  alt=""
                  style="
                    width: 100px;
                    height: 100px;
                    border-radius: 4px;
                    margin: 0 8px 8px 0;
                  "
                  v-for="(itemImg,indexImg) in items.newImages"
                :key="indexImg"
                />
              </div>
            </template>
              <!-- 按钮 -->
              <div class="flex" style="margin-top: 16px">
                <div class="flex flex-content" style="margin-right: 40px">
                  <img
                    src="../../static/oth7.png"
                    alt=""
                    style="width: 16px; height: 16px; margin-right: 8px"
                  />
                  <span>分享</span>
                </div>
                <div class="flex flex-content" style="margin-right: 40px" v-if="items.isLike==0" @click="zanBtn(items)">
                  <img
                    src="../../static/oth8.png"
                    alt=""
                    style="width: 16px; height: 16px; margin-right: 8px"
                  />
                  <span>{{ items.isLike==0  &&items.likeNumber==0?'首赞':items.likeNumber }} </span>
                </div>
                <div class="flex flex-content" style="margin-right: 40px" v-if="items.isLike==1" @click="zanBtn(items)">
                  <img
                    src="../../static/4.png"
                    alt=""
                    style="width: 16px; height: 16px; margin-right: 8px"
                  />
                  <span style="color:rgba(255, 167, 0, 1)">{{ items.isLike==0  &&items.likeNumber==0?'首赞':items.likeNumber }} </span>
                </div>
                <div class="flex flex-content" @click="goPingLun(items)">
                  <img
                    src="../../static/oth9.png"
                    alt=""
                    style="width: 16px; height: 16px; margin-right: 8px"
                  />
                  <span>评论</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
  <div v-else style="color:#333;width: 100%;height: 658px;background: #FFFFFF;border-radius: 8px 8px 8px 8px;">
    <!-- 123123123 -->
    <div class="titleContent">
      <div style="display:flex;justify-content: space-between;align-items: center;padding-top:15px">
      <div>
        动态详情
      </div>
      <div>
        <el-popover style="backgorund:#333" placement="bottom" width="100" trigger="click" >
          <div >
            <div style="display:flex" @click="noSee(guangDetailData)">
              <img src="../../static/234.png" alt="">
              <div style="margin-left:5px">
                不看TA
              </div>
            </div>
            <div style="display:flex;margin-top:5px;" @click="jubao(guangDetailData)">
              <img src="../../static/235.png" alt="">
              <div style="margin-left:5px">
                举报
              </div>
            </div>
          </div>
          <img
            slot="reference"
            style="width: 19px; height: 16px;margin-right:14px"
            src="../../static/212.png"
            alt=""
          />
        </el-popover>
      </div>
    </div>
      <div class="ContentDetail">
        <div class="contentAvato">
          <img :src="guangDetailData.avatar" style="width:100%;height:100%;bor" alt="">
        </div>
        <div class="contentRight">
          <div class="contentRightTitle">
            {{guangDetailData.nickname }}
          </div>
          <div class="contentRightTime">
            {{ guangDetailData.createTimeDesc }}
          </div>
          <div class="contentRightContent">
            <div> <span style="color:rgba(65, 119, 221, 1)" v-if="guangDetailData.topicName">#{{guangDetailData.topicName}}</span> {{ guangDetailData.title }} </div>
          </div>
          <div style="margin-top:10px">
            <template v-if="guangDetailData.newImages.length>0">
              <img style="width:200px;height:200px;margin-right:10px" :src="item" v-for="(item,index) in guangDetailData.newImages" :key="index" alt="">
            </template>
          </div>
          <div style="margin-top:10px">
            <template v-if="guangDetailData.video">
              <video width="420" height="300" controls>
                <source :src="guangDetailData.video"></source>
              </video>
            </template>
          </div>
        </div>
      </div>
      <div class="contentBottom">
        <input
        style="
          margin-top: 30px;
          height: 48px;
          margin-left: 40px;
          width: 700px;
          background: #f8f8f8;
          border: none;
          padding-left: 24px;
          border-radius: 8px 8px 8px 8px;
        "
        placeholder="请输入评论"
        v-model="inputModel"
        @keyup.enter="pingLunSend()"
        
      />
      <div class="sendPingBtn" @click="pingLunSend()">发送</div>
      </div>
    </div>
    <el-dialog
    :modal-append-to-body="false"
    :visible.sync="jubaoShow"
    :show-close="false"
    width="562px"
    :before-close="handleClose"
    style="padding: 0"
  >
    <div style="width: 522px; padding: 0">
      <div class="wheat_remind" style="height: 310px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">举报动态</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click.stop="jubaoShow=false"
          />
        </div>
        <!-- 头部end -->
        <div
          style="
            width: 100%;
            height: 52px;
            background: rgba(255, 114, 104, 0.1);
            color: #ff7268;
            text-align: center;
          "
        >
          <div style="width: 466px">
            举报信息均会经过审核人员的核查处理，恶意举报不会生效，请大家善意利用举报功能！请勿重复举报哦～
          </div>
        </div>
        <div style="margin-top: 24px; margin-left: 29px">举报原因</div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
          "
        >
          <div
            style="display: flex"
            v-for="item in personData"
            :key="item.id"
            @click="personBtn(item)"
          >
            <div v-if="item.isFlag">
              <img
                style="margin-right: 5px"
                src="../../static/219.png"
                alt=""
              />
            </div>
            {{ item.title }}
          </div>
        </div>
        <div class="noGuangchangTitle">
          <div>同时不看TA的广场信息</div>
          <div>
            <el-switch
              v-model="jubaoValue"
            >
            </el-switch>
          </div>
        </div>
        <div class="corfinBtn" @click="jubaoSubmit">确认提交</div>
      </div>
    </div>
  </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    othUserId: null,
  },
  data() {
    return {
      activeName: "first",
      userInfo: {},
      guangchangData: [],
      indexTab: 0,
      audio: "", // 创建音频对象
      videoFile: "",
      isSHowDetail: false,
      guangDetailData: {},
      inputModel: "",
      jubaoShow: false,
      reasonData: [],
      personData: [],
      jubaoValue: true,
      jubaoUserId: "",
      flages: true,
    };
  },
  created() {
    this.getInfo();
    this.getHomepageList();
    this.getforumQueryById();
    this.getQueryReportReason();
  },
  mounted() {
    this.audio = new Audio();
  },
  methods: {
    goPingLun(item) {
      this.isSHowDetail = !this.isSHowDetail;
      this.goGuangDetail(item);
    },
    handleClose() {},
    handleClick(tab, event) {
      this.indexTab = tab.index;
      if (tab.index == 0) {
        this.getforumQueryById();
      }
      if (tab.index == 1) {
        this.getforumMyCollectionList();
      }
    },
    pingLunSend() {
      console.log("aaabbb");
      this.getForumCommentAdd(1, this.guangDetailData.id, this.inputModel);
    },
    getForumCommentAdd(type, id, content) {
      let that = this;
      this.$api
        .forumCommentAdd({
          content: content,
          sourceId: id,
          type: type,
        })
        .then((res) => {
          this.inputModel = "";
          that.pingData = {};
          if (type == 2) {
            that.getForumCommentList(this.guangDetailData);
          }
        });
    },
    // 获取文件时长
    beforeAvatarUpload(file) {
      let url = URL.createObjectURL(file);
      let audioElement = new Audio(url);
      audioElement.addEventListener("loadedmetadata", (_event) => {
        // let playTime = audioElement.duration;
        // this.skillsForm.voiceLen = parseInt(playTime);
      });
    },
    zanBtn(item) {
      this.$api.forumClickAdd({ sourceId: item.id, type: 1 }).then((res) => {
        if (this.indexTab == 0) {
          this.getforumQueryById();
        }
        if (this.indexTab == 1) {
          this.getforumMyCollectionList();
        }
        this.$forceUpdate();
      });
    },
    getInfo() {
      this.$api.queryUser({ otherUserId: this.othUserId }).then((res) => {
        // console.log("总个人信息", res);
        if (res.code == 1) {
          this.userInfo = res.data;
          console.log(this.userInfo, "aaaaaabbbbbaaa");
        }
      });
    },
    getHomepageList() {
      this.$api
        .homepageList({
          othUserId: "1679384132735070209",
          pageNum: 1,
          pageSize: 10,
        })
        .then((res) => {
          console.log(res, "aaaaaa");
        });
    },
    personBtn(item) {
      this.reasonData = item.id;
      item.isFlag = !item.isFlag;
    },
    getQueryReportReason() {
      this.$api.queryReportReason().then((res) => {
        res.data.forEach((item) => {
          item.isFlag = false;
        });
        this.personData = res.data;
      });
    },
    jubao(item) {
      this.jubaoUserId = item.id;
      this.jubaoShow = true;
    },
    jubaoSubmit() {
      // report/report
      if (this.flages) {
        this.flages = true;
        let data = {
          noSee: this.jubaoValue ? 1 : 0,
          type: 3,
          reason: this.reasonData,
          sourceId: this.jubaoUserId,
        };
        this.$api.report(data).then((res) => {
          if (res.code == 1) {
            this.jubaoShow = false;
            this.flages = true;
            this.$message({
              message: "举报成功",
              type: "success",
            });
          }
        });
      }
    },
    getforumQueryById() {
      this.$api.forumMyList().then((res) => {
        // if(rew.d)
        res.data.rows.forEach((item) => {
          if (item.images) {
            item.newImages = item.images.split(",");
          } else {
            item.newImages = [];
          }
        });
        this.guangchangData = res.data.rows;
      });
    },
    playMusic(i) {
      console.log(this.audio);
      this.videoFile = i.video;
      this.audio.src = i.video;
      this.audio.loop = true;
      this.audio.play(); // 播放
    },
    getforumMyCollectionList() {
      this.$api.forumMyCollectionList().then((res) => {
        // if(rew.d)
        res.data.rows.forEach((item) => {
          if (item.images) {
            item.newImages = item.images.split(",");
          } else {
            item.newImages = [];
          }
        });
        this.guangchangData = res.data.rows;
      });
    },
    goGuangDetail(item) {
      this.guangIsSHow = false;
      this.$api.forumQueryById({ id: item.id }).then((res) => {
        if (res.data.images) {
          res.data.newImages = res.data.images.split(",");
        } else {
          res.data.newImages = [];
        }
        this.guangDetailData = res.data;
      });
    },
  },
};
</script>
<style scoped>
.dynamicBox {
  .dynamicItem {
    margin-bottom: 18px;
  }
}
.sendPingBtn {
  position: absolute;
  right: 70px;
  bottom: 6px;
  transform: perspective(-50%, -50%);
  padding: 10px 20px;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
}
.dynamicImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.medalBtn {
  position: absolute;
  left: 50%;
  bottom: 25%;
}
.titleContent {
  width: calc(100% - 36px);
  height: 46px;
  color: #000;
  margin: 0 auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
}
.ContentDetail {
  display: flex;
  width: calc(100% - 32px);
  margin: 40px auto 0;
}
.contentAvato {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.contentRight {
  width: 800px;
  margin-left: 10px;
}
.contentBottom {
  display: flex;
  align-items: center;
  margin: 0 16px;
  position: absolute;
  bottom: 52px;
}
.BottomInp {
  width: 772px;
  height: 48px;
  border: none;
  background-color: #f5f5f5;
  padding: 16px;
  box-sizing: border-box;
}
.contentRightTitle {
  font-size: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.contentRightTime {
  font-size: 12px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-top: 6px;
}
.contentRightContent {
  margin-top: 12px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
}
.prompt_top {
  width: 1210px;
  height: 25px;
  position: fixed;
  z-index: 1;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background: linear-gradient(129deg, #8b39fb 0%, #ec51a3 100%);
}
.noGuangchangTitle {
  width: 466px;
  height: 44px;
  line-height: 44px;

  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px 4px 4px 4px;
  margin: 40px auto 20px;
  box-sizing: border-box;
  padding: 12px 10px;
}
.corfinBtn {
  width: 176px;
  height: 40px;
  background: #ffdd00;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #212121;
  cursor: pointer;
  margin: 0 auto;
}
/deep/ .el-dialog__header {
  display: none;
}
/deep/ .el-tabs__active-bar {
  background-color: rgba(255, 167, 0, 1);
}
</style>